import 'vanilla-cookieconsent/dist/cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

var cc = initCookieConsent();

cc.run({
    current_lang: 'pl',
    autoclear_cookies: true,                   // default: false
    // page_scripts: false,                        // default: false
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    languages: {
        'pl': {
            consent_modal: {
                title: 'Pliki Cookies',
                description: 'Ta witryna korzysta z plików cookies. Korzystanie z tej witryny oznacza zgodę na ich wykorzystywanie. Pliki cookies są wykorzystywane do realizacji usługi, tworzenia liczników, statystyk, monitorowania aktywności odwiedzających oraz zapewnienia jak największej wygody korzystania z serwisu.',
                primary_btn: {
                    text: 'Rozumiem',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                }
            },
            settings_modal: {
                title: 'Preferencje plikÃ³w cookie',
                save_settings_btn: 'Zapisz ustawienia',
                accept_all_btn: 'AkceptujÄ™',
                reject_all_btn: 'OdrzuÄ‡ wszystkie',
                close_btn_label: 'Zamknij',
                cookie_table_headers: [
                    {col1: 'ImiÄ™'},
                    {col2: 'Domena'},
                    {col3: 'WygaÅ›niÄ™cie'},
                    {col4: 'Opis'}
                ],
                blocks: [
                    {
                        title: 'Wykorzystanie plikÃ³w cookie',
                        description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'NiezbÄ™dne pliki cookie',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }
                ]
            }
        }
    },
	gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    }
});