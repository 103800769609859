$('.vote-form').on('submit-success', function(event, data) {
    location.reload();
});
$('.vote-form').on('submit-error', function(event, xhr) {
    event.preventDefault();
    if (xhr.responseText == 'no voting') {
        alert('Głosowanie zakończone.');
    }
    if (xhr.responseText == 'already voted today') {
        alert('Dziś już oddałeś/aś swój głos.');
    }
});