const header = document.querySelector('header');
const navigation = document.querySelector('#navigation');
const navigationToggler = navigation?.querySelector('.navbar-toggler');
const dropdown = document.querySelector('#navigation-dropdown');
const section = document.querySelector('section#grzeski');

dropdown?.querySelectorAll('.nav-item').forEach((item) => {
	if (!item.classList.contains('dropdown')) {
		item.addEventListener('click', () => {
			toggleDropdown();
		});
	}
});

navigationToggler?.addEventListener('click', () => {
	toggleDropdown();
});

const toggleDropdown = () => {
	if (navigationToggler?.getAttribute('aria-expanded') == 'true') {
		navigationToggler?.click();
		header.classList.add('expanded');
	} else {
		header.classList.remove('expanded');
	}
};

const scrollHandler = () => {
	if (window.scrollY > section.offsetTop + section.offsetHeight - header.offsetHeight) {
		header.classList.add('scrolling');
	} else {
		header.classList.remove('scrolling');
	}
}

window.addEventListener('scroll', scrollHandler);
