// Define regex
const regex = {
	type: 'regex',
	options: {
		name: /^[-a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ ]{2,256}$/,
		email: /^(?=^.{6,320}$)([-a-zA-Z0-9_.+"]+@[-a-zA-Z0-9_.+"]+\.[a-zA-Z]{2,10})$/,
		date: /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)[0-9]{2}$/,
		address: /^[-a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9.*\/\\ ]{2,256}$/,
		address_number: /^[-a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9.*\/\\ ]{1,32}$/,
	}
}

export default regex;