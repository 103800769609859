import datepicker from './options/datepicker';
import imasks from './options/imasks';
import regex from './options/regex';
import { validatePolish } from 'validate-polish';

// Define fields
const fields = [
	{
		name: 'firstname',
		validation: {
			type: imasks.type,
			options: imasks.options.name,
		}
	},{
		name: 'lastname',
		validation: {
			type: imasks.type,
			options: imasks.options.name,
		}
	},{
		name: 'email',
		validation: {
			type: regex.type,
			options: regex.options.email,
		}
	},{
		name: 'phone',
		validation: {
			type: imasks.type,
			options: imasks.options.phone,
		}
	},{
		name: 'zipCode',
		validation: {
			type: imasks.type,
			options: imasks.options.postal,
		}
	},{
		name: 'pesel',
		validation: {
			type: imasks.type,
			options: imasks.options.pesel,
			handler: validatePolish.pesel,
		}
	},{
		name: 'birthDay',
		validation: {
			type: regex.type,
			options: regex.options.date,
			datepicker: datepicker.options.dob,
		}
	},{
		name: 'billDate',
		validation: {
			type: regex.type,
			options: regex.options.date,
			datepicker: datepicker.options.bill,
		}
	},{
		name: 'billNip',
		validation: {
			type: imasks.type,
			options: imasks.options.billNip,
			handler: validatePolish.nip,
		}
	},{
		name: 'billNr',
		validation: {
			type: imasks.type,
			options: imasks.options.billNr,
		}
	},{
		name: 'billPos',
		validation: {
			type: imasks.type,
			options: imasks.options.billPos,
		}
	},{
		name: 'iban',
		validation: {
			type: imasks.type,
			options: imasks.options.iban,
		}
	},{
		name: 'street',
		validation: {
			type: regex.type,
			options: regex.options.address,
		}
	},{
		name: 'streetNr',
		validation: {
			type: regex.type,
			options: regex.options.address_number,
		}
	},{
		name: 'flatNr',
		validation: {
			type: regex.type,
			options: regex.options.address_number,
		}
	},{
		name: 'city',
		validation: {
			type: regex.type,
			options: regex.options.address,
		}
	}

];

export default fields;