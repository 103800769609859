import Swiper from 'swiper'
import { Autoplay, Navigation, Thumbs, EffectFade } from 'swiper/modules'
import 'swiper/css'

const swiperLandingThumbs = new Swiper('#swiper-landing-thumbs .swiper', {
	modules: [Autoplay],
	spaceBetween: 16,
	slidesPerView: 1,
	loop: true,
	watchSlidesProgress: true,
	allowTouchMove: false,
	breakpoints: {
		991: {
			slidesPerView: 3,
		},
	}
})
const swiperLanding = new Swiper('#swiper-landing .swiper', {
	modules: [Autoplay, Thumbs, EffectFade, Navigation],
	loop: true,
	autoplay: {
		delay: 10000,
		disableOnInteraction: false,
		pauseOnMouseEnter: false,
	},
    navigation: {
        nextEl: '#swiper-landing .swiper-button-next',
        prevEl: '#swiper-landing .swiper-button-prev',
    },
	thumbs: {
		swiper: swiperLandingThumbs,
	},
})
// on swiperLandingThumbs slide change, add the class "swiper-slide-past" to all slides before current slide
swiperLanding.on('slideChange', () => {
	const slides = swiperLandingThumbs.slides;
	if (slides.length === 0) return;
	// current slide has swiper-slide-thumb-active class, find it by that class
	const currentSlide = slides.find(slide => slide.classList.contains('swiper-slide-thumb-active'));
	// get the index of the current slide
	const currentSlideIndex = Array.from(slides).indexOf(currentSlide);
	// add the class "swiper-slide-past" to all slides before current slide
	for (let i = 0; i < currentSlideIndex; i++) {
		slides[i].classList.add('swiper-slide-past');
	}
	// remove the class "swiper-slide-past" from all slides after current slide
	for (let i = currentSlideIndex; i < slides.length; i++) {
		slides[i].classList.remove('swiper-slide-past');
	}

});

const swiperProducts = new Swiper('#swiper-products .swiper', {
	modules: [Autoplay, Navigation],
	slidesPerView: 1,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
	},
	navigation: {
		nextEl: '#swiper-products .swiper-button-next',
		prevEl: '#swiper-products .swiper-button-prev',
	},
	breakpoints: {
		991: {
			slidesPerView: 3,
		},
	}
})
const updateProductInfo = () => {
	if (swiperProducts.slides.length === 0) return;
	const slideID = swiperProducts.slides[swiperProducts.activeIndex + 1]
	if (!slideID) return;
	const dataTitle = slideID.dataset.productTitle ?? 'Tytuł produktu';
	const dataWeight = slideID.dataset.productWeight ?? '0g';
	const dataDescription = slideID.dataset.productDescription ?? 'Opis produktu';
	const dataButtonText = slideID.dataset.productButtonText ?? 'Kup teraz';
	const dataButtonLink = slideID.dataset.productButtonLink ?? '#';

	const productInfo = document.querySelector('#product-info');
	const productTitle = productInfo.querySelector('#product-title');
	const productWeight = productInfo.querySelector('#product-weight');
	const productDescription = productInfo.querySelector('#product-description');
	const productBuy = productInfo.querySelector('#product-buy');
    
	productTitle.innerHTML = dataTitle;
	productWeight.innerHTML = dataWeight;
	productDescription.innerHTML = dataDescription;
	productBuy.innerHTML = dataButtonText;
	productBuy.setAttribute('href', dataButtonLink);
}
swiperProducts.on('slideChange', () => {
	updateProductInfo();
})
updateProductInfo();

const swiperInstagram = new Swiper('#swiper-instagram .swiper', {
	modules: [Autoplay],
	spaceBetween: 32,
	slidesPerView: 1,
	centeredSlides: true,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
	},
	breakpoints: {
		575: {
			slidesPerView: 2,
			centeredSlides: false,
		},
		991: {
			slidesPerView: 3,
			centeredSlides: false,
		},
		1200: {
			slidesPerView: 4,
			centeredSlides: false,
		},
	}
})

const swiperTiktok = new Swiper('#swiper-tiktok .swiper', {
	modules: [Autoplay],
	spaceBetween: 32,
	slidesPerView: 2,
	centeredSlides: true,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
	},
	breakpoints: {
		991: {
			slidesPerView: 4,
			centeredSlides: false,
		},
	}
})

const swiperYoutube = new Swiper('#swiper-youtube .swiper', {
	modules: [Autoplay, Navigation],
	spaceBetween: 32,
	slidesPerView: 1,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
        pauseOnMouseEnter: true,
	},
	navigation: {
		nextEl: '#swiper-youtube .swiper-button-next',
		prevEl: '#swiper-youtube .swiper-button-prev',
	},
})

const swiperArticles = new Swiper('#swiper-articles .swiper', {
	modules: [Autoplay, Navigation],
	spaceBetween: 32,
	slidesPerView: 1,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
	},
	navigation: {
		nextEl: '#swiper-articles .swiper-button-next',
		prevEl: '#swiper-articles .swiper-button-prev',
	},
	breakpoints: {
		991: {
			slidesPerView: 2,
		},
		1400: {
			slidesPerView: 3,
		},
	}
})