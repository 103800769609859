// Define validation functions
class Validator {
	static setInvalid(field) {
		$(field).addClass('is-invalid').removeClass('is-valid');
		field.setCustomValidity('Invalid');
	}
	static setValid(field) {
		$(field).addClass('is-valid').removeClass('is-invalid');
		field.setCustomValidity('');
	}
	static clear(field) {
		$(field).removeClass('is-valid').removeClass('is-invalid');
		field.setCustomValidity('');
	}
	static isComplete(field, imask) {
		imask.masked.isComplete
		? Validator.setValid(field)
		: Validator.setInvalid(field);
	}
	static isEmpty(field) {
		field.value ? null : Validator.clear(field);
	}
	static pattern(field, pattern) {
		let regex = pattern ? new RegExp(pattern) : null;
		if (regex !== null) regex.test(field.value)
			? Validator.setValid(field)
			: Validator.setInvalid(field);
	}
	static handler(field, value, handler) {

		handler(value)
			? Validator.setValid(field)
			: Validator.setInvalid(field);
	}
	static length(field) {
		let min = $(field).attr('minlength');
		let max = $(field).attr('maxlength');
		if (min) {
			if (field.value.length < min) {
				Validator.setInvalid(field);
				return;
			} else Validator.setValid(field);
		}
		if (max) {
			if (field.value.length > max) {
				Validator.setInvalid(field);
				return;
			} else Validator.setValid(field);
		}
	}
}

export default Validator;